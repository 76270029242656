import {Route, Switch} from 'react-router-dom';
import Index from './index';
import Show from './show';
import Create from './create';

const ResourceRouter = () => (
    <Switch>
      <Route path="/tickets/new" exact component={Create} />
      <Route path="/tickets/:id" exact component={Show} />
      <Route path="/tickets" exact component={Index} />
    </Switch>);

export default ResourceRouter;
