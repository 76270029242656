import PropTypes from 'prop-types';

class BasicDropdown extends React.Component {
  state = { open: false }

  toggle = (e) => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && !prevState.open) {
      // Add the event listener when the dropdown is opened
      document.addEventListener('click', this.handleClickOutside, true);

      if (this.dropdownRef) {
        const buttonGroupWidth = this.dropdownRef.offsetWidth;
        this.setState({ minWidth: buttonGroupWidth });
      }
    } else if (!this.state.open && prevState.open) {
      // Remove the event listener when the dropdown is closed
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  componentWillUnmount() {
    // Clean up the event listener if the component is unmounted
    if (this.state.open) {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  handleClickOutside = () => {
    this.setState({ open: false });
  }

  render() {
    return (
      <div
        className={`btn-group ${this.state.open ? 'show' : ''}`}
        ref={(node) => { this.dropdownRef = node; }}
      >
        {this.props.button}

        <button
          type="button"
          onClick={this.toggle}
          className="btn btn-light dropdown-toggle dropdown-toggle-split"
          aria-haspopup="true"
          aria-expanded={this.state.open}
        >
          <span className="sr-only">Toggle Dropdown</span>
        </button>

        <div
          className={`dropdown-menu dropdown-menu-${this.props.direction} ${this.state.open ? 'show' : ''}`}
          style={{ minWidth: this.state.minWidth }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

BasicDropdown.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  disabled: PropTypes.bool,
}

BasicDropdown.defaultProps = {
  direction: 'left',
  disabled: false,
}
class Dropdown extends React.Component {
  state = { open: false, minWidth: null }

  render() {
    const default_text = this.props.running ? <span className="css-spinloader"></span> : <EllipsisSvg alt="Menü öffnen" className="icon-ellipsis" />;

    return (
      <div className={`dropdown ${this.props.className}`}>
        <button disabled={this.props.disabled} className={this.props.buttonClassName} data-toggle="dropdown">
          {this.props.text ? this.props.text : default_text}
        </button>

        <div className={`dropdown-menu dropdown-menu-${this.props.direction}`}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Dropdown.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  disabled: PropTypes.bool,
  buttonClassName: PropTypes.string,
}

Dropdown.defaultProps = {
  direction: 'left',
  disabled: false,
  buttonClassName: 'btn btn-hoverable',
}

const EllipsisSvg = (props) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>{props.alt}</title>
  <circle cx="10" cy="10" r="2" />
  <circle cx="3" cy="10" r="2" />
  <circle cx="17" cy="10" r="2" />
</svg>)

export { BasicDropdown, Dropdown, EllipsisSvg };

