import Callout from 'client/js/util/callout';
import ExternalLink from 'client/js/util/external_link';
import { transform_data } from 'client/js/util/rest_utils';
import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

const UserTags = (props) => {
  if(props.tags && props.tags.includes('deleted'))
    return <span className="badge badge-danger">Gelöscht</span>;

  return null;
}

const UserLink = (props) => {
  return <Link to={props.user.link.replace('/resellercp', '')}>
      {props.user.username} <UserTags tags={props.user.tags} />
    </Link>
}

const DefaultHeader = (props) => {
  return <tr>{props.fields.map((item, index) => (<th key={index}>{i18n_t(`resources.${props.display_resource_name || props.resource_name}.fields.${item.name}`)}</th>))}</tr>;
}

const TableCell = (props) => {
  if(props.type == 'external_link')
    return <td><ExternalLink href={props.content} text={props.content} /></td>;

  if(props.type == 'external_hostname')
    return <td><ExternalLink href={`https://${props.content}`} text={props.content} /></td>;

  if(props.type == 'user')
    return <td><UserLink user={props.item.user} /></td>;

  if(props.type == 'resource_link')
    return <td><Link to={props.user.link.replace('/resellercp', '')}>XXX {props.item[props.resource_name]['id']}</Link></td>;

  if(props.type == 'component')
    return <td>{props.fn(props.content, props.item, props.refreshCb)}</td>;

  if(props.link == 'show')
    return <td><Link to={`/${props.resource_name}/${props.resource_id}`}>{transform_data(props.type, props.content)}</Link></td>;

  return <td>{transform_data(props.type, props.content)}</td>;
}

TableCell.propTypes = {
  resource_name: PropTypes.string.isRequired,
  resource_id: PropTypes.number.isRequired,
  refreshCb: PropTypes.func.isRequired,
  content: PropTypes.any,
  type: PropTypes.string.isRequired,
  link: PropTypes.string,
  fn: PropTypes.func
}

const CheckedItemsContext = createContext({
  checkedItems: new Set(),
  toggleItem: () => { }
});

const CheckedItemsProvider = ({ toolbar: Toolbar, refreshData, children }) => {
  const [checkedItems, setCheckedItems] = useState(new Set());

  const toggleItem = (id) => {
    setCheckedItems(prev => {
      const newChecked = new Set(prev);
      if (newChecked.has(id)) {
        newChecked.delete(id);
      } else {
        newChecked.add(id);
      }
      return newChecked;
    });
  };

  const clearItems = () => {
    setCheckedItems(new Set());
  }

  return (
    <CheckedItemsContext.Provider value={{ checkedItems, toggleItem }}>
      {children}

      {!!Toolbar && checkedItems.size > 0 && (
        <ActionMenu>
          <Toolbar checkedItems={Array.from(checkedItems)} clearItems={clearItems} refreshData={refreshData} />
        </ActionMenu>
      )}
    </CheckedItemsContext.Provider>
  );
};

const ActionMenu = ({ children }) => {
  // This finds the div we set up in the parent component
  const portalRoot = document.getElementById('action-menu-portal');

  // Render the children inside the portalRoot
  return portalRoot ? ReactDOM.createPortal(children, portalRoot) : null;
};

var Table = function(props) {
  if (!props.data || props.data.length == 0)
    return <Callout calloutClass="primary" title={`Keine ${i18n_t(`resources.${props.display_resource_name || props.resource_name}.plural`)} vorhanden`} text={`Es wurden keine ${i18n_t(`resources.${props.resource_name}.plural`)} gefunden.`} />;

  const header = props.table_header ? <props.table_header /> : <DefaultHeader {...props} />;

  return (
    <CheckedItemsProvider toolbar={props.toolbar} refreshData={props.refreshCb}>
      <div className="table-responsive">
        <table className={`table table-striped table-wide table-${props.namespace}-${props.display_resource_name || props.resource_name} ${props.className}`}>
          <thead>{header}</thead>
          <tbody>
            {props.row ? props.data.map((item, index) => (<props.row key={index} index={index} resource_name={props.resource_name} resource_id={item.id} refreshCb={props.refreshCb} item={item} />)) : props.data.map((item, index) => {
              return <tr key={(item.id || index)}>
                      {props.fields.map((field) => (<TableCell key={field.name}
                                                                            resource_name={props.resource_name}
                                                                            resource_id={item.id}
                                                                            type={field.type}
                                                                            link={field.link}
                                                                            fn={field.fn}
                                                                            refreshCb={props.refreshCb}
                                                                            content={item[field.name]} item={item} />))}
                    </tr>;
            })}
          </tbody>
        </table>
      </div>
    </CheckedItemsProvider>);
}

Table.propTypes = {
  data: PropTypes.array,
  resource_name: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,

  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    link: PropTypes.string
  })).isRequired
}

const Checkbox = ({ id }) => {
  const { checkedItems, toggleItem } = useContext(CheckedItemsContext);
  const isChecked = checkedItems.has(id);

  return (
    <input
      type="checkbox"
      checked={isChecked}
      onChange={() => toggleItem(id)}
    />
  );
};

export { Checkbox, CheckedItemsProvider, Table };
export default Table;
