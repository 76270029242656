import PropTypes from "prop-types";
import {FaIcon, PageHeader, Alert} from 'client/js/util/layout_utils';
import {withLoading} from 'client/js/util/rest_utils';
import {GenericReactForm, TextInput, SubmitButton, FormActions} from 'client/js/util/form_utils';
import AttachmentInput from 'client/js/tickets/attachment_uploader';

const StatusBanner = (props) => {
  if(props.state == 'resolved')
    return <Alert alert_class="success" title={<span><FaIcon name="check-square" /> Vorgang erledigt!</span>} text="Die Bearbeitung des Vorgangs ist abgeschlossen. Sollte es noch weiter Probleme geben oder möchtest Du weitere
    Informationen hinzufügen benutze bitte das Formular unten." />

  if(props.state == 'info_required')
    return <Alert alert_class="warning" title={<strong>Vorgang erledigt!</strong>} text="Für die weitere Bearbeitung des Tickets benötigen wir Informationen. Bitte beantworte die offenen Fragen und wir werden das Ticket weiter bearbeiten." />

  if(props.state == 'wontfix')
    return <Alert alert_class="success" title="Keine Bearbeitung notwendig!" text="Das beschriebene Verhalten ist beabsichtigt und korrekt, es gibt nichts zu tun." />

  if(props.state == 'blocked')
    return <Alert alert_class="info" title="Warten..." text="Das Ticket kann zur Zeit nicht bearbeitet werden, da wir für die Bearbeitung etwas benötigen, das uns noch nicht vorliegt." />

  if(props.state == 'working')
    return <Alert alert_class="info" title="Wird bearbeitet..." text="Das Ticket wird gerade bearbeitet, dies kann etwas dauern..." />

  return <Alert alert_class="info" title="Warten auf Bearbeitung..." text="Das Ticket liegt uns zur Bearbeitung vor. Wir kümmern uns schnellstmöglich um die Erledigung." />
}

const author_name = (post) => {
  if(post.author == 'you')
    return "Ich";

  return "Administrator";
}

const Post = (props) => (<div dangerouslySetInnerHTML={{__html: props.html}} />);

const ShowHandle = (props) => {
  const dispatch = (action) => action == 'form:success' && props.refreshCb();

  return <div>
    <PageHeader text={props.data.title} back_url="/tickets">
      Ticket {props.data.title}
    </PageHeader>

    <StatusBanner state={props.data.state} />

    <table className="table table-striped table-ticket">
      <tbody>
      {props.data.posts.map((item) => <tr key={item.id}>
        <td>
          <small>{format_date(item.created_at)}</small>
          <br/>
          <strong>{author_name(item)}</strong>
          <br />
          {item.attachments > 0 && <div className="mb-2"><small className="text-muted">{i18n_c('attachments', item.attachments)}</small></div>}
        </td>
        <td>
          {item.attachments && item.attachments.length > 0 && (
            <div className="ticket-attachments mb-2">
              {item.attachments.map((attachment) => (
                <a key={attachment.id} href={attachment.url} target="_blank" rel="noopener noreferrer" className="btn btn-light btn-sm"><FaIcon name="paperclip" /> {attachment.filename} ({format_disk_space(attachment.size)})</a>
              ))}
            </div>
          )}

          <Post html={item.html} />
        </td>
      </tr>)}
      </tbody>
    </table>

    <h2>Neue Antwort</h2>

    <GenericReactForm multipart namespace="post" url={`/${window.resource_namespace}/tickets/${props.data.id}/posts`} dispatch={dispatch}>
      <TextInput required name="text" extraClass="ticket_text" label="Text" />

      <AttachmentInput name="file" label="Bild-Dateien" hint="Bitte füge, wenn vorhanden, Screenshots der Fehlermeldung oder des Problems hinzu. Ein Bild sagt mehr aus tausend Worte!" />

      <FormActions>
        <SubmitButton icon="plus" text="Antwort hinzufügen" />
      </FormActions>
    </GenericReactForm>
  </div>;
}

const ShowHandleContainer = withLoading(ShowHandle);

ShowHandle.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};

const Page = (props) => (<ShowHandleContainer url={`/${window.resource_namespace}/tickets/${props.match.params.id}.json`} id={props.match.params.id} key_name="ticket" />)

Page.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default Page;
