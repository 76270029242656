import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

const Usage = ({percent, current, showAbsolute, limit}) => {
  const clamped_percent = Math.max(Math.min(percent, 100), 0);

  let color = "primary";
  if(percent > 90)
    color = "danger";
  else if(percent > 70)
    color = "warning";

  const usage_string = showAbsolute ? format_disk_space(current, 1) : format_percent(percent);

  return <div className="space-usage">
           <div className="percentage">{usage_string} von {format_disk_space(limit, 0)}</div>
           <div className="progress">
             <div className={`progress-bar bg-${color}`} style={{ width: `${clamped_percent}%`}}></div>
           </div>
         </div>
}

Usage.propTypes = {
  showAbsolute: PropTypes.bool,
  percent: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  current: PropTypes.number
};

Usage.defaultProps = {
  showAbsolute: false
};

const fetchData = async (url) => {
  const response = await $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
  });

  return response;
}

const UsageLoader = ({url}) => {
  const {data, isError, isLoading} = useQuery(['usage', url], () => fetchData(url));

  if(isLoading)
    return <div className="css-spinloader inline"></div>;

  if(isError)
    return null;

  return (
    <Usage percent={data?.usage?.percent} current={data?.usage?.current} limit={data?.usage?.limit} />
  )
}

UsageLoader.defaultProps = {
  key_name: "usage",
  inline_spinner: true
}

export {UsageLoader};
export default Usage;
