import { FormActions, GenericReactForm, SubmitButton } from 'client/js/util/form_utils';
import { PageHeader } from 'client/js/util/layout_utils';
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
var inflection = require('inflection');

class CreateResource extends React.Component {
  state = {created: false}

  dispatch = (action, original_event, data) => {
    if(action == "form:success") {
      this.setState({created: true, newRecord: data.object});

      if(this.props.hard_redirect)
        window.location.href = `/${this.props.namespace}${this.redirectUrl(data.object.id)}`;
    }
  }

  redirectUrl = (new_id) => {
    if(this.props.redirectAfter == 'url') {
      return this.props.redirect_url;
    } else if(this.props.redirectAfter == 'show') {
      return `/${this.props.resource_name}/${new_id || this.state.newRecord.id}`;
    } else {
      return `/${this.props.resource_name}`;
    }
  }

  resourceName = () => (this.props.display_resource_name || this.props.resource_name)

  render() {
    if(this.state.created) {
      if(this.props.create_splash)
        return this.props.create_splash(this.state.newRecord);

      return <Redirect to={this.redirectUrl()} />;
    }

    let namespace = inflection.singularize(this.props.resource_name);
    let back_url = this.props.back_url || `/${this.props.resource_name}`;
    if(this.props.breadcrumbs)
      back_url = null;

    if(this.props.form_resource_name)
      namespace = this.props.form_resource_name;

    return <>
      {this.props.breadcrumbs}

      <PageHeader back_url={back_url} text={i18n_t(`resources.${this.resourceName()}.create`)} />

      <GenericReactForm defaults={this.props.defaults} onSuccess={this.props.onSuccess}
                        namespace={namespace} transform_data={this.props.transform_data}
                        url={this.props.form_url || `/${this.props.namespace}/${this.props.resource_name}`}
                        dispatch={this.dispatch} multipart={this.props.multipart}>
        {this.props.form_component()}

        <FormActions>
          <SubmitButton icon="plus" text={i18n_t(`resources.${this.resourceName()}.create`)} />
        </FormActions>
      </GenericReactForm></>;
  }
}

CreateResource.propTypes = {
  namespace: PropTypes.string.isRequired,
  form_component: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.node.isRequired,
  resource_name: PropTypes.string.isRequired,
  display_resource_name: PropTypes.string,
  form_resource_name: PropTypes.string,
  create_splash: PropTypes.func,
  redirect_url: PropTypes.string,
  form_url: PropTypes.string,
  back_url: PropTypes.string,
  hard_redirect: PropTypes.bool,
  transform_data: PropTypes.func,
  onSuccess: PropTypes.func,
  defaults: PropTypes.any,
  redirectAfter: PropTypes.oneOf(['index', 'show']).isRequired
}

CreateResource.defaultProps = {
  namespace: window.resource_namespace,
  redirectAfter: 'show'
}

export default CreateResource;
