import { useQuery } from '@tanstack/react-query';
import { FormActions, GenericReactForm, SubmitButton } from 'client/js/util/form_utils';
import { PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToComponent } from 'client/js/util/rest_utils';
import PropTypes from "prop-types";
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
var inflection = require('inflection');

const fetchData = async (url) => {
  const response = await $.ajax({url: url, dataType: 'json'});

  return response;
}

const UpdateResource = (props) => {
  const [updated, setUpdated] = useState(false);
  const [newRecord, setNewRecord] = useState(null);
  const url = `/${props.namespace}/${props.resource_name}/${props.id}.json`;

  const { data, isLoading, isError, error } = useQuery(['generic_update', url], () => fetchData(url));

  // when we have nested resources, we want to display the child (last) resource name
  const lastResourceName = props.resource_name.split('/').pop();
  const resourceName = inflection.singularize(lastResourceName);

  const dispatch = (action, _o, data) => {
    if(action != "form:success")
      return;

    setUpdated(true);
    setNewRecord(data.object);
  }

  if (updated) {
    if (props.redirectAfter == 'url')
      return <Redirect to={props.redirect_url} />;
    else if (props.redirectAfter == 'show')
      return <Redirect to={`/${props.resource_name}/${props.id}`} />;
    else
      return <Redirect to={`/${props.resource_name}`} />;
  }

  if(isLoading) {
    return (
      <>
        <PageHeader text={i18n_t(`resources.${props.display_resource_name || lastResourceName}.edit`)} back_url={props.back_url || `/${props.resource_name}/${props.id}`} />

        <LoadingTableSpinner />
      </>
    )
  }

  if(isError) {
    return (
      <>
        <PageHeader text={i18n_t(`resources.${props.display_resource_name || lastResourceName}.edit`)} back_url={props.back_url || `/${props.resource_name}/${props.id}`} />

        {errorToComponent(error)}
      </>
    )
  }

  let default_form_data = data[props.data_key_name ? props.data_key_name : resourceName];
  if(props.transform_data)
    default_form_data = props.transform_data(data);

  return (
    <>
      <PageHeader text={i18n_t(`resources.${props.display_resource_name || props.resource_name}.edit`)} back_url={props.back_url || `/${props.resource_name}/${props.id}`} />

      <GenericReactForm namespace={resourceName}
                        verb={props.method}
                        defaults={default_form_data}
                        url={props.url ? props.url : `/${props.namespace}/${props.resource_name}/${props.id}.json`}
                        dispatch={dispatch}>

        {props.form_component()}

        <FormActions>
          <SubmitButton icon="pencil" text={[i18n_t(`resources.${props.display_resource_name || lastResourceName }.singular`),
                                              i18n_t(`resources.actions.update`)].join(' ')} />
        </FormActions>
      </GenericReactForm>
    </>
  );
}

UpdateResource.propTypes = {
  form_component: PropTypes.func.isRequired,
  transform_data: PropTypes.func,
  data_key_name: PropTypes.string,
  display_resource_name: PropTypes.string,
  resource_name: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  redirectAfter: PropTypes.string,
  redirect_url: PropTypes.string,
}

UpdateResource.defaultProps = {
  redirectAfter: 'show',
  method: 'PUT',
}

export default UpdateResource;
