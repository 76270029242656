import { useQuery } from '@tanstack/react-query';
import { UsageLoader } from 'client/js/usercp/lib/usage';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { Pagination, usePages } from 'client/js/util/pagination';
import { errorToComponent, fetchData } from 'client/js/util/rest_utils';
import PropTypes from "prop-types";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Table from './table';

const Header = (props) => {
  let buttons = null;
  let usage = null;

  if(!props.no_create && !props.isError)
    buttons = [<Link key="0" to={`/${props.resource_name}/new`} className="btn btn-light"><FaIcon name="plus" /> {i18n_t(`resources.${props.display_resource_name || props.resource_name}.create`)}</Link>];

  if(props.buttons)
    buttons = props.buttons;

  if(props.usage_url)
    usage = <UsageLoader url={props.usage_url} />;

  const filter = props.filter_transform ? [<input key="search" type="search" className="table-filter search-query form-control" placeholder="Suche" onChange={props.updateFilter} />] : [];

  return <PageHeader back_url={props.back_url} text={i18n_t(`resources.${props.display_resource_name || props.resource_name}.index.header`)} buttons={filter.concat(buttons)} usage={usage} />;
}

const Index = (props) => {
  const [filter, setFilter] = useState(null);
  const [page, changePage] = usePages();

  const { data, isLoading, error, isError, isPreviousData, refetch } = useQuery(
    ['data', props.namespace, props.resource_name, page],
    () => fetchData(props.namespace, props.resource_name, {page: page}),
    {
      keepPreviousData: true,
      refetchInterval: props.refetchInterval
    }
  );

  let display_data = data && data[props.resource_name];

  const IndexHeader = props.header || Header;

  const pagination_data = data && data.pagination;

  if (display_data && display_data.length && props.filter_transform && filter) {
    let filter_fn = null;

    try {
      const rex = new RegExp(filter, 'i');

      filter_fn = (item) => (rex.test(props.filter_transform(item)));
    } catch (err) {
      filter_fn = (item) => (props.filter_transform(item).indexOf(filter) !== -1);
    }

    display_data = display_data.filter(filter_fn);
  }

  let pagination = null;
  if(pagination_data && pagination_data.total_pages > 1) {
    pagination = <Pagination currentPage={pagination_data.current_page} totalPages={pagination_data.total_pages} onPageChange={changePage} />;
  }

  let content = null;

  if(isLoading) {
    content = props.custom_spinner;
  } else if (isError) {
    content = errorToComponent(error)
  } else {
    content = (
      <Table
        namespace={props.namespace}
        className={isPreviousData ? 'skeleton-loading' : ''}
        data={display_data}
        refreshCb={refetch}
        resource_name={props.resource_name}
        error_handler={props.error_handler}
        display_resource_name={props.display_resource_name}
        row={props.row}
        table_header={props.table_header}
        toolbar={props.toolbar}
        fields={props.fields}
        filters={props.filters}
        pagination={pagination}
      />
    )
  }

  return <React.Fragment>
      <IndexHeader {...props} isError={isError} data={data} updateFilter={(e) => setFilter(e.target.value)} />

      {typeof(props.tabs) === 'function' ? props.tabs(data) : props.tabs}

      {pagination}

      {content}

      {pagination}

    </React.Fragment>;
}

const IndexContainer = (props) => (<Index custom_spinner={props.spinner || <LoadingTableSpinner />} {...props} />)

IndexContainer.propTypes = {
  namespace: PropTypes.string.isRequired,
  resource_name: PropTypes.string.isRequired,
  display_resource_name: PropTypes.string,
  no_create: PropTypes.bool.isRequired,
  tabs: PropTypes.node,
  usage_url: PropTypes.string,
  error_handler: PropTypes.func,
  header: PropTypes.element,
  refetchInterval: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    link: PropTypes.string
  })).isRequired,

  filters: PropTypes.objectOf(PropTypes.shape({
    collection: PropTypes.string
  }))
}

IndexContainer.defaultProps = {
  namespace: window.resource_namespace,
  no_create: false,
  refetchInterval: false
}

export default IndexContainer;
