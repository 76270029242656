import React, { useState } from 'react';

const Pagination = (props) => {
  const { currentPage, totalPages, onPageChange } = props;

  if(totalPages === 1)
    return null;

  const handleClick = (e, page) => {
    e.preventDefault();
    onPageChange(page);
  }

  const pages = [];
  const maxPagesBeforeCurrent = 5;
  const maxPagesAfterCurrent = 5;

  const startPage = Math.max(1, currentPage - maxPagesBeforeCurrent);
  const endPage = Math.min(totalPages, currentPage + maxPagesAfterCurrent);

  // Add ellipsis and first page if needed
  if (currentPage > 1) {
    pages.push(<li key="laq" className={currentPage === 1 ? 'disabled page-item' : 'page-item'}><a href="?page=1" className='page-link' role="button" onClick={(e) => handleClick(e, 1)}>&laquo;</a></li>);
    pages.push(<li key="lsaq" className={currentPage === 1 ? 'disabled page-item' : 'page-item'}><a href={`?page=${currentPage - 1}`} className='page-link' role="button" onClick={(e) => handleClick(e, currentPage - 1)}>&lsaquo;</a></li>);
    if(startPage > 1)
      pages.push(<li key="before-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
  }

  // Add pages
  for (let i = startPage; i <= endPage; i++) {
    pages.push(<li key={i} className={currentPage === i ? 'active page-item' : 'page-item'}><a href={`?page=${i}`} className='page-link' role="button" onClick={(e) => handleClick(e, i)}>{i}</a></li>);
  }

  // Add ellipsis and last page if needed
  if (currentPage < totalPages) {
    if(endPage < totalPages)
      pages.push(<li key="after-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);

    pages.push(<li key="rsaq" className={currentPage === totalPages ? 'disabled page-item' : 'page-item'}><a href={`?page=${currentPage + 1}`} className='page-link' role="button" onClick={(e) => handleClick(e, currentPage + 1)}>&rsaquo;</a></li>);
    pages.push(<li key="raq" className={currentPage === totalPages ? 'disabled page-item' : 'page-item'}><a href={`?page=${totalPages}`} className='page-link' role="button" onClick={(e) => handleClick(e, totalPages)}>&raquo;</a></li>);
  }

  return (
    <nav>
      <ul className="pagination">
        {pages}
      </ul>
    </nav>
  );
};

const withPagination = (TableComponent) => {
  const WithPaginantionComponent = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;
    const totalPages = Math.ceil(props.items.length / itemsPerPage);

    const onPageChange = (page) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
      }
    };

    const paginatedItems = props.items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
      <>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

        <TableComponent {...props} items={paginatedItems} />

        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
      </>
    );
  };

  WithPaginantionComponent.displayName = `WithPagination(${TableComponent.displayName || TableComponent.name || 'Component'})`;
  return WithPaginantionComponent;
};

const getDefaultPage = () => {
  const url = new URL(window.location.href);
  const currentPage = Number(url.searchParams.get('page'));

  return currentPage.isNaN || currentPage < 1 ? 1 : currentPage;
}

const usePages = () => {
  const [currentPage, setCurrentPage] = useState(getDefaultPage());

  const onPageChange = (page) => {
    const url = new URL(window.location.href);
    url.searchParams.set('page', page);
    // set the new url to the browser without triggering a reload
    window.history.pushState({}, '', url);

    setCurrentPage(page);
  }

  return [currentPage, onPageChange];
}

export default withPagination;

export { Pagination, usePages };
