import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToToast } from 'client/js/util/rest_utils';
import PropTypes from "prop-types";
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';

class ConfirmedDelete extends React.Component {
  state = {modal_open: false}

  handleFirstClick = (e) => {
    e.preventDefault();
    this.setState({modal_open: true});
  }

  handleAbort = (e) => {
    e.preventDefault();
    this.setState({modal_open: false, failed: false, error_message: null});
  }

  handleDelete = (e) => {
    e.preventDefault();

    this.setState({actionRunning: true});

    $.ajax({url: `/${this.props.namespace}/${this.props.resource}/${this.props.id}`, type: 'DELETE', dataType: 'json'})
      .done(() => {
        this.setState({actionRunning: true, deleted: true, modal_open: false});
        if(this.props.success_cb)
          this.props.success_cb();

        if (this.props.onSuccess)
          this.props.onSuccess();
      })
      .fail((xhr) => {
        errorToToast(xhr, "beim Löschen");

        this.setState({actionRunning: false});
      });
  }

  render() {
    if(this.props.redirect_back && this.state.deleted) {
      const url = this.props.redirectUrl ? this.props.redirectUrl : `/${this.props.resource}`;
      return <Redirect to={url} />;
    }

    return <>
        {this.props.link ?
         <a href="#" disabled={this.props.disabled} className={this.props.button_class} onClick={this.handleFirstClick}>{this.props.button_text}</a> :
        <button disabled={this.props.disabled} className={this.props.button_class} onClick={this.handleFirstClick}>{this.props.button_text}</button>}

        {this.state.modal_open && <Modal isOpen={this.state.modal_open} className="modal fade in show d-block modal-no-borders" contentLabel={this.props.title}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{this.props.title}</h5>
                  <button type="button" onClick={this.handleAbort} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                {this.state.actionRunning ?
                  <div className="modal-body"><LoadingTableSpinner /></div> :
                  <div className="modal-body">
                    {this.props.text}
                  </div>}

                <div className="modal-footer">
                  <button disabled={this.state.actionRunning} className="btn btn-light" onClick={this.handleAbort}>abbrechen</button>
                  <button disabled={this.state.actionRunning} className="btn btn-danger" onClick={this.handleDelete}>{this.props.confirmButtonText}</button>
                </div>
              </div>
              </div>
            </Modal>}
      </>;
  }
}

ConfirmedDelete.propTypes = {
  namespace: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  button_class: PropTypes.string,
  button_text: PropTypes.node.isRequired,
  confirmButtonText: PropTypes.string,
  disabled: PropTypes.bool,
  success_cb: PropTypes.func,
  onSuccess: PropTypes.func,
  redirect_back: PropTypes.bool,
  link: PropTypes.bool,
  redirectUrl: PropTypes.string
};

ConfirmedDelete.defaultProps = {
  namespace: 'usercp',
  button_text: 'löschen',
  title: 'Wirklich löschen?',
  text: 'Wirklich löschen?',
  confirmButtonText: 'löschen',
  redirect_back: true,
  link: false
}

export default ConfirmedDelete;
