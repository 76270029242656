import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

const title_fn = (id, item) => {
  let badges = [];

  if(item.new_answer)
    badges.push(<span className="badge badge-info ml-1">Ungelesene Antwort</span>);

  if(item.resolved)
    badges.push(<span className="badge badge-success ml-1">Abgeschlossen</span>);

  if(item.info_required)
    badges.push(<span className="badge badge-warning ml-1">Informationen benötigt</span>);

  const link = <Link key="0" className="mr-1" to={`/tickets/${item.id}`}>{item.title}</Link>;
  if(badges.length)
    return <div className="flex-column-wrapper">{link} <div>{badges}</div></div>;
  else
    return link;
}

const Header = (props) => {
  const buttons = <><Link to={`/${props.resource_name}/new`} className="btn btn-light"><FaIcon name="plus" /> {i18n_t(`resources.${props.display_resource_name || props.resource_name}.create`)}</Link></>;

  return <PageHeader text={i18n_t(`resources.tickets.index.header`)} buttons={buttons}>
          {i18n_t(`resources.tickets.index.header`)}
          {props.data && props.data.support_pin ? <small className="text-muted ml-1">Support-PIN: {props.data.support_pin}</small> : null}
         </PageHeader>;
}

Header.propTypes = {
  data: PropTypes.shape({
    support_pin: PropTypes.string
  }),
  display_resource_name: PropTypes.string,
  resource_name: PropTypes.string.isRequired
}

const fields = [{name: "id", type: "text", link: "show"},
                {name: "title", type: "component", fn: title_fn},
                {name: "created_at", type: "datetime"}]

const Index = () => (<GenericIndex resource_name="tickets"
                                   header={Header}
                                   fields={fields} />);

export default Index;
