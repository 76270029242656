import { withRouter } from 'react-router'
import React from 'react';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);

      if(_paq) {
        _paq.push(['setCustomUrl', '/usercp' + this.props.location.pathname]);
        _paq.push(['setDocumentTitle', 'My New Title']);
        _paq.push(['trackPageView']);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop)
