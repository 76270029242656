import PropTypes from "prop-types";
import {ControlledInput, ControlledErrors} from "client/js/util/form_tags";
import {ControlGroup} from "client/js/util/form_utils";

var PostalAndCityInput = function(props, context) {
  const n = [context.namespace, "postal_and_city"].filter((n) => (n != undefined)).join('_');
  return (
    <ControlGroup name={n} labelFor={n} required label={props.label} hint={props.hint}>
      <div className="input-group">
        <ControlledInput required extraClass="col-md-3" name={props.prefix_name} type="text" />
        <ControlledInput required extraClass="col-md-9" name={props.domain_name} type="text" />
      </div>
      <ControlledErrors names={[props.prefix_name, props.domain_name]} />
    </ControlGroup>);
}

PostalAndCityInput.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string
};

PostalAndCityInput.defaultProps = {
  prefix_name: 'postal',
  domain_name: 'city',
  separator: '.',
  label: 'Subdomain'
};

PostalAndCityInput.contextTypes = {
  namespace: PropTypes.string,
};

export default PostalAndCityInput;
