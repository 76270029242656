import ExternalLink from 'client/js/util/external_link';
import {StringInput, TextInput, BooleanInput} from 'client/js/util/form_utils';
import GenericCreate from 'client/js/resellercp/generic_resource/create';
import AttachmentInput from 'client/js/tickets/attachment_uploader';

const Form = () => {
  return <div>
      <StringInput required name="title" label="Betreff" />
      <TextInput required name="text" extraClass="ticket_text" label="Ausführliche Problembeschreibung" />

      <AttachmentInput name="file" label="Bild-Dateien" hint="Bitte füge, wenn vorhanden, Screenshots der Fehlermeldung oder des Problems hinzu. Ein Bild sagt mehr aus tausend Worte!" />

      <BooleanInput name="notify" label="Ich möchte bei einer Antwort per E-Mail benachrichtigt werden" />
      <BooleanInput name="hints_read" label={<span>Ich habe die <ExternalLink href="/hilfe/kategorie/haeufig-gestellte-fragen" text="FAQ" /> gelesen, die <ExternalLink href="http://lima-status.de/" text="Status-Seite" />, besucht und die <ExternalLink href="/hilfe" text="Hilfe" /> durchsucht.</span>} />
    </div>;
}
const Create = () => (<GenericCreate resource_name="tickets"
                                     multipart
                                     form_component={() => (<Form />)} />);

export default Create;
