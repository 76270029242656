import PropTypes from "prop-types";
import {PageHeader} from 'client/js/util/layout_utils';
import {Redirect} from 'react-router-dom';
import {GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';
var inflection = require('inflection');

class Interaction extends React.Component {
  state = {created: false}

  dispatch = (action, original_event, data) => {
    if(action == "form:success") {
      this.setState({created: true, newRecord: data.object});

      if(this.props.onSuccess) {
        this.props.onSuccess(data.object);
      }

      if(this.props.hard_redirect)
        window.location.href = `/${this.props.namespace}${this.redirectUrl(this.props.id)}`;
    }
  }

  redirectUrl = () => {
    if(this.props.redirectAfter == 'url') {
      //if redirect_url is a function, call it with the new record
      if(typeof this.props.redirect_url === 'function') {
        return this.props.redirect_url(this.state.newRecord);
      }

      return this.props.redirect_url;
    } else if(this.props.redirectAfter == 'show') {
      return `/${this.props.resource_name}/${this.props.id}`;
    } else {
      return `/${this.props.resource_name}`;
    }
  }

  resourceName = () => (this.props.display_resource_name || this.props.resource_name)

  formUrl = () => {
    if(this.props.form_url)
      return this.props.form_url;

    return `/${this.props.namespace}/${this.props.resource_name}/${this.props.id}/${this.props.action}`
  }

  backUrl = () => {
    if(this.props.back_url === false)
      return null;

    if(this.props.back_url)
      return this.props.back_url;

    if(this.props.id)
      return `/${this.props.resource_name}/${this.props.id}`
    else
      return `/${this.props.resource_name}`
  }

  render() {
    if(this.state.created) {
      if(this.props.success_splash)
        return this.props.success_splash(this.props.id);

      return <Redirect to={this.redirectUrl()} />;
    }

    return <React.Fragment>
      {!!this.props.breadcrumbs && this.props.breadcrumbs}

      <PageHeader regular_link={this.props.regular_back_link} back_url={this.backUrl()} text={i18n_t(`resources.${this.resourceName()}.interactions.${this.props.action}`)} />

      <GenericReactForm defaults={this.props.defaults} verb={this.props.method}
                        namespace={inflection.singularize(this.props.form_namespace || this.props.resource_name)}
                        url={this.formUrl()}
                        dispatch={this.dispatch}>
        {this.props.form_component()}

        <FormActions>
          <SubmitButton icon={this.props.icon_name} text={this.props.button_text || i18n_t(`resources.${this.resourceName()}.interactions.${this.props.action}`)} />
        </FormActions>
      </GenericReactForm>
    </React.Fragment>;
  }
}

Interaction.propTypes = {
  namespace: PropTypes.string.isRequired,
  form_component: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.node,
  resource_name: PropTypes.string.isRequired,
  form_namespace: PropTypes.string,
  form_url: PropTypes.string,
  back_url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  regular_back_link: PropTypes.bool,
  action: PropTypes.string.isRequired,
  icon_name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  display_resource_name: PropTypes.string,
  create_splash: PropTypes.func,
  redirect_url: PropTypes.string,
  button_text: PropTypes.string,
  hard_redirect: PropTypes.bool,
  defaults: PropTypes.any,
  redirectAfter: PropTypes.oneOf(['index', 'show']).isRequired,
  onSuccess: PropTypes.func,
}

Interaction.defaultProps = {
  namespace: window.resource_namespace,
  redirectAfter: 'show',
  method: 'POST'
}

export default Interaction;
