import PropTypes from "prop-types";
import {FaIcon} from 'client/js/util/layout_utils';
import Dropzone from 'react-dropzone'

class AttachmentUploader extends React.Component {
  state = {files: []}

  addFile = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      let f = prevState.files
      f.push(null)
      return {files: f};
    });
  }

  removeFile = (e, index) => {
    e.preventDefault();
    this.setState((prevState) => {
      let f = prevState.files;
      f.splice(index, 1);

      return {files: f};
    });
  }

  onDrop = (accepted_files) => {
    const files = [...this.state.files, ...accepted_files];
    this.setState({files});
    this.context.formDispatch('value_changed', null, {name: this.props.name, value: files})
  }

  render() {
    return (
      <div className={`row form-group file optional ${this.context.namespace}_${this.props.name}`}>
        <div className="col-md-3">
          {this.props.label}
        </div>
        <div className="col-md-9">
          {this.state.files.map((file, index) => {
            return <div key={index} className="mb-1">
                <FaIcon name="remove fa-fw" onClick={(e) => this.removeFile(e, index)} />
                Anhang #{index + 1}: {file.name} ({format_disk_space(file.size)})
            </div>;
          })}
          <Dropzone className="btn btn-light" onDrop={this.onDrop}>
            <FaIcon name="paperclip" /> <span className="d-none d-md-inline">Dateien hierher ziehen oder klicken zum Hinzufügen</span> <span className="d-inline d-md-none">Dateien hinzufügen</span>
          </Dropzone>
        </div>
      </div>
    )
  }
}

AttachmentUploader.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  extraClass: PropTypes.string,
};

AttachmentUploader.defaultProps = {
  type: 'text'
};

AttachmentUploader.contextTypes = {
  namespace: PropTypes.string,
  serverErrors: PropTypes.object,
  formDispatch: PropTypes.func
};

export default AttachmentUploader;
