import Callout from 'client/js/util/callout';
import { DetailsRow, DetailsSection, PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';

import { Line as LineChart } from "react-chartjs-2";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, TimeScale);

const Usage = ({ current, limit, percent }) => {
  let color = "success";
  if (percent >= 80)
    color = "warning";
  if (percent >= 95)
    color = "danger";

  return (
    <div className="data-usage pull-right">
      {format_disk_space(current)} von {format_disk_space(limit)}

      <div className="progress" style={{ width: '10rem' }}>
        <div className={`progress-bar bg-${color}`} role="progressbar" style={{ width: `${percent}%` }} aria-valuenow={current} aria-valuemin="0" aria-valuemax={limit}>
          {format_percent(percent)}
        </div>
      </div>
    </div>
  )
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
  },
  interaction: {
    mode: 'index',
    axis: 'x',
    intersect: false
  },
  scales: {
    x: {
      type: 'time',
      title: {
        display: false
      },
      time: {
        unit: 'day'
      },
    },
    y: {
      title: {
        display: false
      },
      ticks: {
        callback: (value) => (value.fileSize())
      }
    }
  }
}


const pvOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
  },
  interaction: {
    mode: 'index',
    axis: 'x',
    intersect: false
  },
  scales: {
    x: {
      type: 'time',
      title: {
        display: false
      },
      time: {
        unit: 'day'
      },
    },
    y: {
      title: {
        display: false
      },
      ticks: {
        callback: (value) => (number_with_delimiter(value))
      }
    }
  }
}

// Show a badge with the change in percent from previous to current

const Diff = ({ current, previous }) => {
  if(previous === 0 && current && current > 0)
    return <span className="ml-2 badge bg-success">+∞</span>;

  if(previous === 0)
    return null;

  const diff = current - previous;
  const color = diff >= 0 ? "success" : "warning";

  return (
    <span className={`ml-2 badge bg-${color}`}>
      {diff > 0 && '+'}{format_percent(diff / previous * 100)}
    </span>
  )
}

const WebhostingRow = ({ webhosting }) => {
  const {webspace_usage, database_usage, email_usage} = webhosting;

  const processedData = { traffic: [], page_views: [] };
  let totalTraffic = 0;
  let totalPageViews = 0;

  webhosting.stats.forEach((entry) => {
    const { date, bytes, page_views } = entry;

    totalTraffic += bytes;
    totalPageViews += page_views;

    processedData.traffic.push({x: date, y: bytes});
    processedData.page_views.push({x: date, y: page_views});
  });

  const trafficChartData = {
    datasets: [{
      label: 'Traffic',
      data: processedData.traffic, // Choose metric here (e.g., requests)
      fill: false,
      borderColor: "#669DE8",
      tension: 0.1
    }]
  };

  const pageviewsChartData = {
    datasets: [{
      label: 'Page Views',
      data: processedData.page_views, // Choose metric here (e.g., requests)
      fill: false,
      borderColor: '#669DE8',
      tension: 0.1
    }]
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-4">
          <h5>Webhosting-Speicherplatz</h5>

          <DetailsSection>
            <DetailsRow title="Webspace-Belegung:">
              <Usage {...webspace_usage} />
            </DetailsRow>
            <DetailsRow title="Datenbank-Speicher:">
              <Usage {...database_usage} />
            </DetailsRow>

            <DetailsRow title="E-Mail-Belegung:">
              <Usage {...email_usage} />
            </DetailsRow>
          </DetailsSection>
        </div>

        <div className="col-md-4">
          <h5>Seitenaufrufe der letzten 14 Tage <Diff previous={webhosting.previous_page_views} current={totalPageViews} /></h5>

          <p>
            {number_with_delimiter(totalPageViews)} gesamt

            <small className="text-muted ml-2">{number_with_delimiter(webhosting.previous_page_views)} vom {dayjs(webhosting.previous_first_day).format('L')} bis {dayjs(webhosting.previous_last_day).format('L')}</small>
          </p>

          <LineChart data={pageviewsChartData} options={pvOptions} height={100} />
        </div>

        <div className="col-md-4">
          <h5 className="d-flex align-items-center">Traffic der letzten 14 Tage <Diff previous={webhosting.previous_bytes} current={totalTraffic} /></h5>

          <p>
            {format_disk_space(totalTraffic)} gesamt

            <small className="text-muted ml-2">{format_disk_space(webhosting.previous_bytes)} vom {dayjs(webhosting.previous_first_day).format('L')} bis {dayjs(webhosting.previous_last_day).format('L')}</small>
          </p>

          <LineChart data={trafficChartData} options={options} height={100} />
        </div>
      </div>
    </>
  )
}

const ProgressPreview = ({ color, title }) => (
  <>
    <div className="progress-preview">
      <div className={`progress-bar bg-${color}`}>
      </div>
    </div> {title}
  </>
)

const StackedProgress = ({ data }) => {
  const sum = data.reduce((acc, item) => acc + item.value, 0);

  return  (
    <div className="progress my-3 progress-stacked">
      {data.map(({color, value}) => (
        value > 0 && <div key={[color, value].join(':')} className={`progress-bar bg-${color}`} role="progressbar" style={{ width: `${value / sum * 100}%` }} aria-valuenow={value} aria-valuemin="0" aria-valuemax={sum}>
        </div>
      ))}
    </div>
  )

}

const BalanceInfo = ({ affiliate, tos_accepted }) => {
  const progressData = [
    {value: affiliate.open_commissions.amount, color: 'info'},
    {value: affiliate.available_balance, color: 'secondary'},
    {value: affiliate.paid_commissions, color: 'primary'}
  ]

  return (
    <>
      <h5>Provisionen</h5>

      {!tos_accepted && (
        <Callout calloutClass="warning">
          <p>Um Provisionen für geworbene Kunden zu erhalten akzeptiere bitte die <Link to="/commissions">Teilnahmebedingungen</Link>.</p>
        </Callout>
      )}

      <StackedProgress data={progressData} />

      <DetailsSection className="dashboard-data">
        <DetailsRow title={<ProgressPreview color="info" title="Offene Provisionen:" />}>
          {format_fractional_price(affiliate.open_commissions.amount)}
        </DetailsRow>

        <DetailsRow title={<ProgressPreview color="secondary" title="Verfügbarer Betrag:" />}>
          {format_fractional_price(affiliate.available_balance)}
        </DetailsRow>

        <DetailsRow title={<ProgressPreview color="primary" title="Ausgezahlte Provisionen:" />}>
          {format_fractional_price(affiliate.paid_commissions)}
        </DetailsRow>
      </DetailsSection>
    </>
  )
}

const UpcomingDomainRenewals = ({ domains }) => {
  if(domains.length === 0) {
    return (
      <>
        <h5>Domainverlängerungen</h5>
        <p className="text-muted">Keine bevorstehenden Domainverlängerungen</p>
      </>
    )
  }

  // show only the first 5 domains
  const domainsList = domains.slice(0, 5);

  return (
    <>
      <h5>Anstehende Domainverlängerungen</h5>

      <DetailsSection className="dashboard-data">
        {domainsList.map((domain) => (
          <DetailsRow key={domain.id} title={domain.unicode_fqdn}>
            {dayjs(domain.registered_until).format('L')}
          </DetailsRow>
        ))}
      </DetailsSection>

      {domains.length > 5 && (
        <p className="text-muted">{domains.length - 5} weitere Domainverlängerungen vorhanden!</p>
      )}
    </>
  )
}

const AffiliateRow = ({ affiliate, domains }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-4 col-xl-4">
          <BalanceInfo affiliate={affiliate} tos_accepted={affiliate.tos_accepted} />
        </div>

        <div className="col-md-4 col-xl-4">
          <UpcomingDomainRenewals domains={domains} />
        </div>
      </div>
    </>
  )
}

const Dashboard = () => {
  const { data, component } = useResource(["dashboard"], '/usercp', {
    keepPreviousData: true,
    header: <PageHeader text="Dashboard" />
  });

  if (component)
    return component;

  return (
    <>
      <PageHeader text="Dashboard" />

      {data.webhosting.enabled && (
        <>
          <WebhostingRow webhosting={data.webhosting} />

          <hr />
        </>
      )}

      <AffiliateRow affiliate={data.affiliate} domains={data.upcoming_domain_renewals} />
    </>
  );
}

export { BalanceInfo, ProgressPreview, StackedProgress };

export default Dashboard;
